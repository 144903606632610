let en =  {
  Introduction:"Introduction",
  Open:"Open",
  Bid:"Bid",
  Biddings:"Biddings",
  Ends:"Ends in",
  SellBlindBox:"Sell Mystery Box",
  SendAsGift:"Send as Gift",
  NFTID:"NFT ID",
  Contract:"Contract",
  PurchasePrice:"Purchase Price",
  interestedTitle:"You may also be interested in ...",
  Collection:"Collection",
  ViewMore:"View More...",
  NFTPool:"NFT Pool",
  inputPlaceholder:"Artworks, publishers, etc.",
  Pricelowtohigh:"Price：Low to High",
  Pricehigntolow:"Price：High to Low",
  Caption:"Caption",
  Tag:"Tag"
}

let zh = {
  Introduction:"",
  Open:"",
  Bid:"",
  Biddings:"",
  Ends:"",
  SellBlindBox:"",
  SendAsGift:"",
  NFTID:"",
  Contract:"",
  PurchasePrice:"",
  interestedTitle:"",
  Collection:"",
  ViewMore:"",
  NFTPool:"",
  inputPlaceholder:"",
  Pricelowtohigh:"",
  Pricehigntolow:"",
  Caption:"",
  Tag:""
}

let name = 'BlindBoxesDetail'

export {
name, en, zh
}