import ERC20Token from '@/assets/jsonFile/abis/ERC20-ABI.json'
import ERC20Faucet from "@/assets/jsonFile/abis/ERC20Faucet.json"
import marketBlindBox from '@/assets/jsonFile/abis/tokenBoxAbis/marketBlindBox.json'
import marketMultipleNFT from '@/assets/jsonFile/abis/tokenBoxAbis/marketMultipleNFT.json'
import marketNFTInBox from '@/assets/jsonFile/abis/tokenBoxAbis/marketNFTInBox.json'
import setChainID from '@/js/chain/chainID'
import store from '@/store'

export const contractAddress = () =>{
    let address
    let ChainID = setChainID()
    let currentChain = store.state.wallet.chainName.toUpperCase()
    for(let i in ChainID){
        if(currentChain===i){
            address = ChainID[i].contractAddress
            break;
        }
        address = {};
    }
    return address
}
export const initContracts = async () => {
    let address = await contractAddress()
    return {
        SEORToken:new (window as any).web3.eth.Contract(ERC20Token,address.SEORAddress),
        FaucetContract:new (window as any).web3.eth.Contract(ERC20Faucet,address.faucetAdress),
        MarketBlindBox:new (window as any).web3.eth.Contract(marketBlindBox,address.blindboxAddress),
        MarketMultipleNFT:new (window as any).web3.eth.Contract(marketMultipleNFT,address.MultipleNFTAddress),
        MarketNFTInBox:new (window as any).web3.eth.Contract(marketNFTInBox,address.NFTInBoxAddress)
    };
};

