let en =  {
  title:"Mystery Box Collection",
  All:"All",
  Selling:"Selling",
  showAllThemes:"Show All Themes",
  inputPlaceholder:"Artworks, publishers, etc.",
  Pricelowtohigh:"Price：Low to High",
  Pricehigntolow:"Price：High to Low",
  Collection:"Collection",
  BlindBoxName:"Mystery Box Name",
  BlindBoxSn:"Mystery Box SN",
  ContractAddress:"Contract Address",
  OnTheForm:"On The Form",
  SellBlindBox:"Sell Mystery Box",
  AuctionBlindBox:"Auction Mystery Box",
  BiddingTime:"Bidding Time",
  Day:"Day",
  Hour:"Hour",
  Minute:"Minute",
  ServiceCharge:"Service Charge",
  SellIt:"Sell It",
  Cancel:"Cancel",
  PresentationAddress:"Presentation Address",
  SendAsGift:"Send as Gift"
}

let zh = {
  title:"",
  All:"",
  Selling:"",
  showAllThemes:"",
  inputPlaceholder:"",
  Pricelowtohigh:"",
  Pricehigntolow:"",
  Collection:"",
  BlindBoxName:"",
  BlindBoxSn:"",
  ContractAddress:"",
  OnTheForm:"",
  SellBlindBox:"",
  AuctionBlindBox:"",
  BiddingTime:"",
  Day:"",
  Hour:"",
  Minute:"",
  ServiceCharge:"",
  SellIt:"",
  Cancel:"",
  PresentationAddress:"",
  SendAsGift:""
}

let name = 'BlindBoxCollection'

export {
name, en, zh
}