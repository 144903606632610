let polkadotChain = {
    "CANVAS":{
        name: "Canvas",
        url: "assets/common/chainIcon/Moonbase.png",
        nativeCurrency: {
            name: "DOT",
            symbol: "DOT"
        },
        contractAddress:{
            SEORAddress: '0xA711D7097021a27B9E430B1a7A949c49238fB066',
            Peripheral: '0xFBCc0a8910b7DC87318020884A5dd55824D7DD52',
            faucetAdress: "0x7a8f0e8e6117B44338188D3646421297EB1888B0",
            TimeLockAddress: "0x910Cb80E0B99766e64382E8658E41F55Fcc00912",
            TokenBoxAddress: "0x1E4088c8b8EE5641cFEb911c7792081E3D29A200",
            OpenBoxAddress: "0x638F64fB73957C87C9BFd9DbacF9e3e55c853E0a",
            BoxDistributor: "0x8b1A2533196b07be7FC48B4812A9d460698aFEE8"
        }
    }
}

export default polkadotChain;