let en =  {
  Introduction:"Seal Oracle Low-Code Development Platform.",
  Obtain:"You can get it here",
  Start:"let's start"
}

let zh = {
  Introduction:"低代码开发平台（LCDP）是无需编码（0代码）或通过少量代码就可以快速生成应用程序的开发平台。",
  Obtain:"你可以在这里获得",
  Start:"开始吧"
}

let name = 'home'

export {
  name, en, zh
}