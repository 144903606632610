let chainId = {
  'MOONBASE_ALPHA': {
    name: "Moonbase_Alpha",
    url: require("@/assets/common/chainIcon/Moonbase.png"),
    ID: "1287",
    rpcUrl: "https://rpc.api.moonbase.moonbeam.network",
    blockExplorerUrls: "https://moonbase.moonscan.io",
    nativeCurrency: {
      name: "DEV",
      symbol: "DEV",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0xA711D7097021a27B9E430B1a7A949c49238fB066',
      Peripheral: '0xFBCc0a8910b7DC87318020884A5dd55824D7DD52',
      faucetAdress: "0x7a8f0e8e6117B44338188D3646421297EB1888B0"
    }
  },
  'GATECHAIN-TEST': {
    name: "GateChain-Test",
    url: require("@/assets/common/chainIcon/GateScan.png"),
    ID: "85",
    rpcUrl: "https://meteora-evm.gatenode.cc",
    blockExplorerUrls: "https://gatescan.org/testnet/",
    nativeCurrency: {
      name: "GT",
      symbol: "GT",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0xEb603b88ce0F99B092777D008b3d354C6210b58b',
      Peripheral: '0x7D589A2Eb7A1B1c67648A082BE8c77A5Aa77b04e',
      faucetAdress: "0x8AD7738b134443e84DeFadFde78B0d297C09c1B1"
    }
  },
  'AVAX-FUJI': {
    name: "Avax-Fuji",
    url: require("@/assets/common/chainIcon/avax.png"),
    ID: "43113",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    blockExplorerUrls: "https://testnet.snowtrace.io/",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x7D589A2Eb7A1B1c67648A082BE8c77A5Aa77b04e',
      Peripheral: '0xEb603b88ce0F99B092777D008b3d354C6210b58b',
      faucetAdress: "0x6Bb4B39Eda975Eb494703aecE975870E0FdEbff0"
    }
  },
  'MATIC-MUMBAI': {
    name: "MATIC-Mumbai",
    url: require("@/assets/common/chainIcon/polygon.png"),
    ID: "80001",
    chainName: "Matic(Polygon) Testnet Mumbai",
    rpcUrl: "https://rpc-mumbai.matic.today",
    blockExplorerUrls: "https://mumbai.polygonscan.com/",
    nativeCurrency: {
      name: "tMATIC",
      symbol: "tMATIC",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x83a213a6290c8c49D7512074798792C0f77c18Ca',
      Peripheral: '0x9935BE0faAC45e545Af0b2896788eb9F4702518e',
      faucetAdress: "0xE1fe13c7bD98D1b5CFeceC8Cf18e235c8dF5F953",
    }
  },
  'ROPSTEN': {
    name: "Ropsten",
    url: require("@/assets/common/chainIcon/ETHIcon.png"),
    ID: "3",
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "rETH",
      symbol: "rETH",
      decimals: 18
    },
    isStart: true,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'BSC-TESTNET': {
    name: "BSC-TESTNET",
    url: require("@/assets/common/chainIcon/BSCIcon.png"),
    ID: "97",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB",
      decimals: 18
    },
    isStart: false,
    isInvisible: false,
    contractAddress: {
      // SEORAddress: '0x96789dDdB00796Aecec932696DC70f4c25DA3F71',
      // blindboxAddress: "0xc7850e86F85B9785A5D92358Eb639CB83EF23bb0",
      // NFTInBoxAddress:"0xd5AF56D5E6C454cD6194446f29A38C9a6c3977F0",
      // MultipleNFTAddress:"0xA5e97EE35b938C8550145A462726Db7b8230eCA4",
      // faucetAdress:'0x4468f4aD86Aed4eF340a0cF3f029339057E19455',
      // SEORAddress: '0xf78F74B343664848478Ab0b7a126519788FFd87f',
      // blindboxAddress: "0x0d9dfCfbd52c9894aEAbD3Df5DD14846d3EB7107",
      // NFTInBoxAddress:"0xD56389BF62B01dEF0D20bc166457c3bF0F7ae09D",
      // MultipleNFTAddress:"0x882319C4c9E17E28b9DF6538cD165979D7811420",
      // faucetAdress:'0x3Ac1FB408bAe63ea1b549F6B660c466Fdd4b4f7f',
      SEORAddress: '0x96789dDdB00796Aecec932696DC70f4c25DA3F71',
      blindboxAddress: "0xa07F8279a8bc98dc05188AD5A4Fa82FD23901547",
      NFTInBoxAddress:"0x7E4A964eEaD52e2A48Fe2401f05A9888a3C4CaC9",
      MultipleNFTAddress:"0x4c30A790614a8168026c85bc0C7e804e90c85fAc",
      faucetAdress:'0xF24e44A3857c4084dDCABD21E6a61e6bb3A5E8fe',
    }
  },
  'BSC-MAINNET': {
    name: "BSC-Mainnet",
    url: require("@/assets/common/chainIcon/BSCIcon.png"),
    ID: "56",
    rpcUrl: "https://bsc-dataseed1.binance.org",
    blockExplorerUrls:"https://bscscan.com",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18
    },
    isStart: false,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x800a25741A414Ea6E6e2B382435081A479A8Cc3c',
      blindboxAddress: "0x96789dDdB00796Aecec932696DC70f4c25DA3F71",
      NFTInBoxAddress:"0xc7850e86F85B9785A5D92358Eb639CB83EF23bb0",
      MultipleNFTAddress:"0xe681894E645b56CEeE30b5a7d16c8b413C613394",
      // faucetAdress:'0x7BA4c0957aA91b20B679Ee646229721cb84Dd92D',
    }
  },
  'ETHEREUM': {
    name: "Ethereum",
    url: require("@/assets/common/chainIcon/ETHIcon.png"),
    ID: "1",
    rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    isStart: false,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'RINKEBY': {
    name: "Rinkeby",
    url: require("@/assets/common/chainIcon/ETHIcon.png"),
    ID: "4",
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "rkETH",
      symbol: "rkETH",
      decimals: 18
    },
    isStart: false,
    isInvisible: true,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'GOERLI': {
    name: "Goerli",
    url: require("@/assets/common/chainIcon/ETHIcon.png"),
    ID: "5",
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "gETH",
      symbol: "gETH",
      decimals: 18
    },
    isStart: false,
    isInvisible: true,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'KOVAN': {
    name: "Kovan",
    url: require("@/assets/common/chainIcon/ETHIcon.png"),
    ID: "42",
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "kETH",
      symbol: "kETH",
      decimals: 18
    },
    isStart: false,
    isInvisible: true,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'HECO': {
    name: "HECO",
    url: require("@/assets/common/chainIcon/HECIcon.png"),
    ID: "128",
    rpcUrl: "https://http-mainnet-node.huobichain.com",
    nativeCurrency: {
      name: "HT",
      symbol: "HT",
      decimals: 18
    },
    isStart: false,
    isInvisible: false,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'HECO-TESTNET': {
    name: "HECO-TESTNET",
    url: require("@/assets/common/chainIcon/HECIcon.png"),
    ID: "256",
    rpcUrl: "https://http-testnet.hecochain.com",
    blockExplorerUrls: "https://testnet.hecoinfo.com",
    nativeCurrency: {
      name: "htt",
      symbol: "htt",
      decimals: 18
    },
    isStart: false,
    isInvisible: true,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
  'MATIC': {
    name: "MATIC",
    url: require("@/assets/common/chainIcon/polygon.png"),
    ID: "137",
    chainName: "Matic(Polygon) Mainnet",
    rpcUrl: "https://rpc-mumbai.matic.today",
    blockExplorerUrls: "https://mumbai.polygonscan.com/",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    isStart: false,
    isInvisible: true,
    contractAddress: {
      SEORAddress: '0x9b36F988baD73dfAe232d5F5B77ef9Dac3847eD6',
      Peripheral: '0x0C78E2DF411F5e7AA630a90aba796e5947177683',
      faucetAdress: "0x19A80F8DaED62148Dd6b1b83BAC097DE09408F56"
    }
  },
}


function setChainID() {
  for (let i in chainId) {
    (window as any).ethereum ? chainId[i].ID = (window as any).web3.utils.toHex(chainId[i].ID) : null
  }
  return chainId
}

export default setChainID