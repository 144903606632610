import {i18nBuilder} from "../../tools";

let i18n = i18nBuilder([
  require('./BlindBoxes'),
  require('./BlindBoxesDetail')
])

let en = i18n['en']
let zh = i18n['zh']
let name = "components"

export {
  en, zh, name
}