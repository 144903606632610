import router from '@/router'
import setChainID from '@/js/chain/chainID'
import { getUserInfo } from '@/js/network/serverApi/PersonalCenter'
import { getTokenBalance, getTokenSymbol } from '@/js/contracts/erc20'

export default {
  state: {
    address: '',
    chainName: '',
    symbol: '',
    balance:'0.00',
    ChainId: '',
    walletType: '',
    userInfo:{},
    hideWarning:false,
    defaultAvatar:"",
  },
  getters: {
    address: state => state.address,
    chainName: state => state.chainName,
    symbol: state => state.symbol,
    ChainId: state => state.ChainId,
    walletType: state => state.walletType,
    userInfo: state => state.userInfo,
    defaultAvatar: state => state.defaultAvatar
  },
  mutations: {
    updateAccountsChanged(state, payload) {
      state.address = payload;
    },
    updateSymbol(state, payload) {
      state.symbol = payload;
    },
    updateBalance(state, payload) {
      state.balance = payload;
    },
    updateUserInfo(state, payload){
      state.userInfo = payload;
    },
    updateChainName(state, payload) {
      let chainId = setChainID()
      state.ChainId = payload
      for (let i in chainId) {
        if (chainId[i].ID === payload) {
          state.chainName = chainId[i].name;
          state.symbol = chainId[i].nativeCurrency.symbol;
          return
        }
      }
      state.chainName = payload;
    },
    updatePolkadotChainName(state, payload){
      state.chainName = payload;
    },
    updateChainId(state, payload) {
      state.ChainId = payload;
    },
    updateWalletType(state, payload) {
      state.walletType = payload;
    },
    updateHideWarning(state, payload) {
      state.hideWarning = payload
    },
    updateDefaultAvatar(state, payload){
      state.defaultAvatar = payload
    }
  },
  actions: {
    enableMetaMaskWallet({ dispatch,commit, state, rootState }) {
      // commit('updateConnectWalletLoading', true, { root: true })
      (window as any).ethereum.request({ method: 'eth_requestAccounts' }).then(async(accounts) => {
        dispatch('connectWalletOperate', {address:accounts[0],walletType:"MetaMask"})
        let acctounInfo = await getUserInfo(accounts[0])
        commit('updateUserInfo',acctounInfo['data'].data)
        let balance = await getTokenBalance(accounts[0])
        let symbol = await getTokenSymbol();
        commit('updateBalance',balance);
        commit('updateSymbol',symbol);
      }).catch((error) => {
        commit('updateConnectWalletLoading', false)
        commit('updateConnectWalletErrLoading', true)
      })
    },
    connectWalletOperate({ commit, state, rootState },params){
      router.push({ name: "home" })
      commit('updateAccountsChanged', params.address)
      commit('updateConnectWallet', false)
      commit('updateConnectWalletErrLoading', false)
      commit('updateConnectWalletLoading', false)
      commit('updateWalletType',params.walletType)
    },
    async switchChain({ commit, state }, parpms) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: parpms.ID }],
        });
        parpms.runFunction()
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const walletParam = {
              "chainId": parpms.ID,
              "chainName": parpms.name,
              "rpcUrls": [parpms.rpcUrl],
              "nativeCurrency": parpms.nativeCurrency,
              "blockExplorerUrls": [parpms.blockExplorerUrls]
            }
            await (window as any).ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [walletParam]
            });
            parpms.runFunction()
          } catch (addError) {

          }
        }
      }
    }
  }
};
