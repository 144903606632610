const Activity = {
  path: '/Activity',
  name:'Activity',
  redirect:'/ActivityNFT',
  component: () => import('@/views/Activity/index.vue'),
  children:[
    {
      path: '/ActivityNFT',
      name: 'ActivityNFT',
      component: () => import('@/views/Activity/ActivityNFT.vue'),
    },
    {
      path: '/ActivityNFTDetail',
      name: 'ActivityNFTDetail',
      component: () => import('@/views/Activity/ActivityNFTDetail.vue'),
    },
    {
      path: '/ActivityBox',
      name: 'ActivityBox',
      component: () => import('@/views/Activity/ActivityBox.vue'),
    },
    {
      path: '/ActivityBoxDetail',
      name: 'ActivityBoxDetail',
      component: () => import('@/views/Activity/ActivityBoxDetail.vue'),
    },
    {
      path: '/ActivityNFTDataDetail',
      name: 'ActivityNFTDataDetail',
      component: () => import('@/views/Activity/ActivityNFTDataDetail.vue'),
    },
  ]
}

export default Activity;