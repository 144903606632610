let en =  {
  title:"Events",
  NFTEvent:"NFT Event",
  BlindBoxEvent:"MysteryBox Event",
  Opening:"Opening in",
  days:"days"
}

let zh = {
  title:"",
  NFTEvent:"",
  BlindBoxEvent:"",
  Opening:""
}

let name = 'events'

export {
name, en, zh
}