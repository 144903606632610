const Markets = {
  path: '/Markets',
  name:'Markets',
  redirect:'/MarketNFT',
  component: () => import('@/views/Market/index.vue'),
  children:[
    {
      path: '/MarketNFTS',
      name: 'MarketNFTS',
      component: () => import('@/views/Market/MarketNFTS.vue'),
    },
    {
      path: '/MarketNFTDetail',
      name: 'MarketNFTDetail',
      component: () => import('@/views/Market/MarketNFTDetail.vue'),
    },
    {
      path: '/MarketBlindBoxes',
      name: 'MarketBlindBoxes',
      component: () => import('@/views/Market/MarketBlindBoxes.vue'),
    },
    {
      path: '/MarketBlindBoxDetail',
      name: 'MarketBlindBoxDetail',
      component: () => import('@/views/Market/MarketBlindBoxDetail.vue'),
    },
    {
      path: '/OpenBoxResult',
      name: 'OpenBoxResult',
      component: () => import('@/views/PersonalCenter/OpenBoxResult.vue'),
    },
  ]
}

export default Markets;