import ERC20Token from '@/assets/jsonFile/abis/ERC20-ABI.json'
import ERC20Faucet from "@/assets/jsonFile/abis/ERC20Faucet.json"
import polkadotChain from '@/js/chain/polkadot'
import { useStore } from 'vuex'
const store = useStore()

export const contractAddress = () =>{
    let address
    let ChainID = polkadotChain
    let currentChain = store.state.wallet.chainName.toUpperCase()
    for(let i in ChainID){
        if(currentChain===i){
            address = ChainID[i].contractAddress
        }
    }
    return address
}
export const initContracts = async () => {
    let address = await contractAddress()
    return {
        SEORToken:new (window as any).web3.eth.Contract(ERC20Token,address.SEORAddress),
        FaucetContract:new (window as any).web3.eth.Contract(ERC20Faucet,address.faucetAdress),
    };
};

