let en =  {
  Explore:"Explore",
  Create:"Create",
  My:"My",
  NFTS:"NFTS",
  NFT:"NFT",
  BlindBox:"Mystery Box",
  BecomeAPublisher:"Become a Publisher",
  Orders:"Orders",
  History:"History",
  Rights:"All Rights Reserved ©"
}

let zh = {
  Explore:"Explore",
  Create:"Create",
  My:"My",
  NFTS:"NFTS",
  NFT:"NFT",
  BlindBox:"Mystery Box",
  BecomeAPublisher:"Become a Publisher",
  Orders:"Orders",
  History:"History",
  Rights:"All Rights Reserved ©"
}

let name = 'about'

export {
  name, en, zh
}