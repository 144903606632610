let en =  {
  Introduction:"Introduction",
  Buy:"Buy",
  Ends:"Ends in",
  SellThisNFT:"Sell this NFT",
  SendAsGift:"Send as Gift",
  NFTID:"NFT ID",
  Contract:"Contract",
  PurchasePrice:"Purchase Price",
  interestedTitle:"You may also be interested in ...",
  Collection:"Collection",
  ViewMore:"View More..."
}

let zh = {
  Introduction:"",
  Buy:"",
  Ends:"",
  SellThisNFT:"",
  SendAsGift:"",
  NFTID:"",
  Contract:"",
  PurchasePrice:"",
  interestedTitle:"",
  Collection:"",
  ViewMore:""
}

let name = 'NFTDetail'

export {
name, en, zh
}