const PersonalCenter = {
  path: '/PersonalCenter',
  name: 'PersonalCenter',
  component: () => import('@/views/PersonalCenter/index.vue'),
  redirect:'/NFTCollection',
  children:[
    {
      path: '/NFTCollection',
      name: 'NFTCollection',
      component: () => import('@/views/PersonalCenter/NFTCollection.vue'),
    },
    {
      path: '/BlindBoxCollection',
      name: 'BlindBoxCollection',
      component: () => import('@/views/PersonalCenter/BlindBoxCollection.vue'),
    },
    {
      path: '/Bidding',
      name: 'Bidding',
      component: () => import('@/views/PersonalCenter/Bidding.vue'),
    },
    {
      path: '/History',
      name: 'History',
      component: () => import('@/views/PersonalCenter/History.vue'),
    },
    {
      path: '/Message',
      name: 'Message',
      component: () => import('@/views/PersonalCenter/Message.vue'),
    },
    {
      path: '/Setting',
      name: 'Setting',
      component: () => import('@/views/PersonalCenter/Setting.vue'),
    },
    {
      path: '/AddNFTActivity',
      name: 'AddNFTActivity',
      component: () => import('@/views/PersonalCenter/components/NFTActivity/AddNFTActivity.vue')
    },
    {
      path: '/ModifyNFTActivity',
      name: 'ModifyNFTActivity',
      component: () => import('@/views/PersonalCenter/components/NFTActivity/ModifyNFTActivity.vue')
    },
    {
      path: '/NFTActivity',
      name: 'NFTActivity',
      component: () => import('@/views/PersonalCenter/NFTActivity.vue')
    },
    {
      path: '/NFTActivityData',
      name: 'NFTActivityData',
      component: () => import('@/views/PersonalCenter/components/NFTActivity/NFTActivityData.vue')
    },
    {
      path: '/BlindBoxActivity',
      name: 'BlindBoxActivity',
      component: () => import('@/views/PersonalCenter/BlindBoxActivity.vue')
    },
    {
      path: '/AddBlindBoxActivity',
      name: 'AddBlindBoxActivity',
      component: () => import('@/views/PersonalCenter/components/BlindBoxActivity/AddBlindBoxActivity.vue')
    },
    {
      path: '/ModifyBlindBoxActivity',
      name: 'ModifyBlindBoxActivity',
      component: () => import('@/views/PersonalCenter/components/BlindBoxActivity/ModifyBlindBoxActivity.vue')
    },
    {
      path: '/BlindBoxActivityData',
      name: 'BlindBoxActivityData',
      component: () => import('@/views/PersonalCenter/components/BlindBoxActivity/BlindBoxActivityData.vue')
    },
    // {
    //   path: '/OpenBoxResult',
    //   name: 'OpenBoxResult',
    //   component: () => import('@/views/PersonalCenter/OpenBoxResult.vue'),
    // },
  ]
}

export default PersonalCenter;

