let en =  {
  title:"Featured Mystery Boxes",
  Collection:"Collection",
  ViewMore:"View More"
}

let zh = {
  title:"",
  Collection:"",
  ViewMore:""
}

let name = 'FeaturedBlindBoxes'

export {
name, en, zh
}