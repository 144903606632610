import Web3js from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'

let provider = null

async function isMetaMask (){
  const Provider = await detectEthereumProvider()
  if(Provider){
    if(Provider !== window.ethereum){
      console.error('Do you have multiple wallets installed?');
      return false
    }
    return true
  }else{
    return false
  }
}

async function loadWallet(){
  provider = await detectEthereumProvider()
  if(provider){
    if(provider !== window.ethereum){
      console.error('Do you have multiple wallets installed?');
      return null
    }
    (window as any).web3 = new Web3js(provider)
    return (window as any).web3
  }else{
    return null
  }
}

async function getChainId(){
  let ChainId = await (window as any).web3.eth.getChainId()
  return (window as any).web3.utils.toHex(ChainId)
}

export default { 
  loadWallet, 
  getChainId,
  isMetaMask
};
