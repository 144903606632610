let en =  {
  Back:"Back",
  ConnectWallet:"Connect Wallet",
  content1:"By connecting a wallet, you agree to SEOR",
  content2:"Terms of Service",
  content3:"and acknowledge that you have read and understand the",
  content4:"SEOR protocol disclaimer",
  Initializing:"Initializing...",
  error:"Error connecting...",
}

let zh = {
  Back:"Back",
  ConnectWallet:"连接钱包",
  content1:"通过连接钱包，您同意 SEOR 的",
  content2:"服务条款",
  content3:"并确认您已阅读并理解",
  content4:"SEOR 协议免责声明",
  Initializing:"正在初始化...",
  error:"连接错误...",
}

let name = 'connectWallet'

export {
  name, en, zh
}