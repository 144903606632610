let en =  {
  Collections:"Collections",
  NFTS:"NFTS",
  BlindBoxes:"Mystery Boxes",
  Transaction:"Transaction",
  Bidding:"Bidding",
  History:"History",
  NFTActivity:"NFT Activity",
  BlindBoxActivity:"MysteryBox Activity",
  Activity:"Activity"
}

let zh = {
  Collections:"",
  NFTS:"",
  BlindBoxes:"",
  Transaction:"",
  Bidding:"",
  History:"",
  NFTActivity:"",
  BlindBoxActivity:"",
  Activity:""
}

let name = 'PersonalCenter'

export {
name, en, zh
}