import axios from 'axios'
import {apiServerBaseUrl} from "@/js/network/constant";
import store from '@/store'

async function parseResponse(res) {
  if(res.err) {
    let data = {}
    if(res.err.response) {
      data = {
        status: res.err.response.status,
        data: res.err.response.data
      }
    }
    return new Promise(r=>{
      r({data: data, err: res.err})
    })
  } else {
    return new Promise(r=>{
      r({data: res.data, err: null})
    })
  }
}

async function doGet(uri) {
  let ChainID = await (window as any).web3.eth.getChainId();
  let toHexChainId = (window as any).web3.utils.toHex(ChainID);
  let res = await axios.get(`${apiServerBaseUrl}/${toHexChainId}${uri}`)
    .catch(err=>{
      return {err: err}
    })

  return parseResponse(res)
}

async function doPost(uri, data, headers = {}) {
  let ChainID = await (window as any).web3.eth.getChainId();
  let toHexChainId = (window as any).web3.utils.toHex(ChainID)
  let res = await axios.post(`${apiServerBaseUrl}/${toHexChainId}${uri}`, data, {
    headers: Object.assign(headers, { 'Content-Type': 'application/json' })
  }).catch(err=>{
      return {err: err}
    })

  return parseResponse(res)
}

export default { 
  doGet,
  doPost
}