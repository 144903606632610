export default {
  state: {
    isMobile: false,
    isDrawer: false, 
    navActive:"",
    routerName:""
  },
  getters: {
    isMobile: state => state.isMobile,
    isDrawer: state => state.isDrawer,
    navActive : state => state.navActive,
    routerName: state => state.routerName
  },
  mutations: {
    changeIsMobile(state, payload) {
      state.isMobile = payload
    },
    changeIsDrawer(state, payload) {
      state.isDrawer = payload
    },
    updateNavActive(state, payload) {
      state.navActive = payload
    },
    updateRouterName(state, payload){
      state.routerName = payload
    }
  },
  actions: {
    changeMobile: ({ commit }, flag) => {
      commit('changeIsMobile', flag);
    },
    changeDrawer: ({ commit }, flag) => {
      commit('changeIsDrawer', flag);
    }
  }
};
