const CreateNFT = {
  path: '/CreateNFT',
  name:'CreateNFT',
  redirect:'/CreateNFT',
  component: () => import('@/views/CreateNFT/index.vue'),
  children:[
    {
      path: '/CreateNFT',
      name: 'CreateNFT',
      component: () => import('@/views/CreateNFT/createNFT.vue'),
    },
  ]
}

export default CreateNFT