let en =  {
  title:"Featured NFTS",
  Collection:"Collection",
  ViewMore:"View More"
}

let zh = {
  title:"",
  Collection:"",
  ViewMore:""
}

let name = 'FeaturedNFTs'

export {
name, en, zh
}