import main from './mian'
import home from './home'
import popUps from './popUps'
import NFTS from './NFTS'
import BlindBoxes from './BlindBoxes'
import createNFT from './CreateNFT'
import Events from './Events'
import Themes from './Themes'
import BecomePublisher from './BecomePublisher'
import PersonalCenter from './PersonalCenter'
import faucet from './faucet'

export default {
  Main: main.i18n['zh'],
  Home: home.i18n['zh'],
  PopUps: popUps.i18n['zh'],
  NFTS: NFTS.i18n['zh'],
  BlindBoxes: BlindBoxes.i18n['zh'],
  CreateNFT: createNFT.i18n['zh'],
  Events: Events.i18n['zh'],
  Themes: Themes.i18n['zh'],
  BecomePublisher: BecomePublisher.i18n['zh'],
  PersonalCenter: PersonalCenter.i18n['zh'],
  Faucet: faucet.i18n['zh'],
}