let en =  {
  title:"NFT Collection",
  All:"All",
  Selling:"Selling",
  showAllThemes:"Show All Themes",
  inputPlaceholder:"Artworks, publishers, etc.",
  Pricelowtohigh:"Price：Low to High",
  Pricehigntolow:"Price：High to Low",
  Collection:"Collection",
  NFTName:"NFT Name",
  ContractAddress:"Contract Address",
  OnTheForm:"On The Form",
  SellNFT:"Sell NFT",
  AuctionNFT:"Auction NFT",
  BiddingTime:"Bidding Time",
  Day:"Day",
  Hour:"Hour",
  Minute:"Minute",
  ServiceCharge:"Service Charge",
  SellIt:"Sell It",
  Cancel:"Cancel",
  PresentationAddress:"Presentation Address",
  SendAsGift:"Send as Gift"
}

let zh = {
  title:"",
  All:"",
  Selling:"",
  showAllThemes:"",
  inputPlaceholder:"",
  Pricelowtohigh:"",
  Pricehigntolow:"",
  Collection:"",
  NFTName:"",
  ContractAddress:"",
  OnTheForm:"",
  SellNFT:"",
  AuctionNFT:"",
  BiddingTime:"",
  Day:"",
  Hour:"",
  Minute:"",
  ServiceCharge:"",
  SellIt:"",
  Cancel:"",
  PresentationAddress:"",
  SendAsGift:""
}

let name = 'NFTCollection'

export {
name, en, zh
}