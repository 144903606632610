let en =  {
  Faucet:"Test SEOR Faucet",
  request:"Receive 50 Test SEOR per request",
  Address:'Enter Your Test Address',
  Send:"Send Test SEOR",
  Warn:"The interval is too short since the last collection",
  NotAvailable:"Currently unavailable！",
  ReceivedSuccessfully:"Received successfully"
}

let zh = {
  Faucet:"Test SEOR Faucet",
  request:"Receive 50 Test SEOR per request",
  Address:'输入您的钱包地址',
  Send:"Send Test SEOR",
  Warn:'自上次收集以来的间隔太短',
  NotAvailable:"当前不可领取！",
  ReceivedSuccessfully:"领取成功"
}

let name = 'faucetAddress'

export {
  name, en, zh
}