let en =  {
  ETH :"left in Faucet.Gas Limit",
  Pending:"Pending",
  History:"History"
}

let zh = {
  ETH :"留在 Faucet.Gas Limit",
  Pending:"等待",
  History:"历史"
}

let name = 'faucetHistory'

export {
  name, en, zh
}