let i18nBuilder = function (textArr) {
  let i18n = {}

  textArr.forEach(p => {
    let languages = Object.keys(p)
    languages.forEach(l => {
      if('name' ===  l) {
        return
      }
      if(!i18n[l]) {
        i18n[l] = {}
      }

      i18n[l][p.name] = p[l]
    })
  })

  return i18n
}

export {
  i18nBuilder
}