


export default {
  state: {
    active: '', // 当前选中
    isCollapse: false, // 是否折叠
  },
  getters: {
    isGet: state => {
      return state.isGet
    },
  },
  mutations: {
    SET_IS_GET: (state, isGet) => {
      state.isGet = isGet
    },
    SET_ACTIVE: (state, active) => {
      state.active = active
    },
    SET_IS_COLLAPSE: (state, isCollapse) => {
      state.isCollapse = isCollapse
    },
    CLEAR: state => {
      state.isCollapse = false
      state.isGet = false
      state.menus = []
      state.permissions = []
    }
  },
  actions: {
    setActive ({ commit }, active) {
      commit('SET_ACTIVE', active)
    },
    setIsCollapse ({ commit }, isCollapse = false) {
      commit('SET_IS_COLLAPSE', isCollapse)
    },
    clear ({ commit }) {
      commit('CLEAR')
    }
  }
}
