let en =  {
  title:"Mystery Boxes",
  showAllThemes:"Show All Themes",
  inputPlaceholder:"Artworks, publishers, etc.",
  Pricelowtohigh:"Price：Low to High",
  Pricehigntolow:"Price：High to Low",
  Collection:"Collection"
}

let zh = {
  title:"",
  showAllThemes:"",
  inputPlaceholder:"",
  Pricelowtohigh:"",
  Pricehigntolow:"",
  Collection:""
}

let name = 'BlindBoxes'

export {
name, en, zh
}