let en =  {
    SEORLCDP:"SEOR-LCDP",
    SeorContent:"Seal Oracle low code development platform",
    SeorWord:"The SEOR-LCDP platform adopts the open source development mode to continuously expand the user ecology. One of the main objectives is to effectively reduce  the technical cost of main public chain ecological technicians crossing the chain to other public chains, as well as the difficulty of application implementation of traditional Internet developers in blockchain  world technology development, so as to allow users to more conveniently and quickly access and use the ecological ability of different blockchain technologies,Expand the ecological boundaries of different public chains",
    Start:"let's start"

}

let zh = {
    SEORLCDP:"SEOR-LCDP",
    SeorContent:"Seal Oracle low code development platform",
    SeorWord:"The SEOR-LCDP platform adopts the open source development mode to continuously expand the user ecology. One of the main objectives is to effectively reduce  the technical cost of main public chain ecological technicians crossing the chain to other public chains, as well as the difficulty of application implementation of traditional Internet developers in blockchain  world technology development, so as to allow users to more conveniently and quickly access and use the ecological ability of different blockchain technologies,Expand the ecological boundaries of different public chains",
    Start:"let's start"
}

let name = 'seorlcdp'

export {
  name, en, zh
}