let en =  {
  title:"Biddings",
  Bids:"Bids",
  Received:"Received",
  Name:"Name",
  ContractAddress:"Contract Address",
  Time:"Time",
  MyBid:"My Bid",
  Status:"Status",
  Success:"Success",
  OutBid:"Ongoing-Out-bid",
  HighestBid:"Ongoing-Highest Bid",
  Ended:"Ended",
  Collection:"Collection"
}

let zh = {
  title:"",
  Bids:"",
  Received:"",
  Name:"",
  ContractAddress:"",
  Time:"",
  MyBid:"",
  Status:"",
  Success:"",
  OutBid:"",
  HighestBid:"",
  Ended:"",
  Collection:""
}

let name = 'Bidding'

export {
name, en, zh
}