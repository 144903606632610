
declare const window: any
const Web3 = require('web3');
import Decimal from 'decimal.js';
const defaultGas = 6000000
const approveValue = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

export async function getTokenDecimals() {
  return await window.contract.SEORToken.methods.decimals().call()
}

export async function getTokenSymbol() {
  return await window.contract.SEORToken.methods.symbol().call()
}

export async function getTokenBalance(account:string) {
  const decimals=  await window.contract.SEORToken.methods.decimals().call()
  const balanceof = await window.contract.SEORToken.methods.balanceOf(account).call()
  return new Decimal(balanceof).div(new Decimal(Math.pow(10,decimals))).toFixed(2)
}

export async function approve( from:string, spender:string) {
  const gas = await window.contract.SEORToken.methods.approve(
    spender,
    approveValue
  ).estimateGas({ from: from }).catch(() => {
    return defaultGas;
  });
  return await window.contract.SEORToken.methods.approve(spender, approveValue).send({ from: from, gas: gas })
}

export async function allowance( owner:string, spender:string,) {
  const value = await window.contract.SEORToken.methods.allowance(owner, spender).call()
  return value
}