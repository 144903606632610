let en =  {
  switchChainId:"Switch Chain",
}

let zh = {
  switchChainId:"切换网络",
}

let name = 'switchChain'

export {
  name, en, zh
}