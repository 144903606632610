import {i18nBuilder} from "../../tools";

let i18n = i18nBuilder([
  require('./personalCenter'),
  require('./NFTCollection'),
  require('./BlindBoxCollection'),
  require('./Bidding'),
  require('./History')
])

let en = i18n['en']
let zh = i18n['zh']
let name = "components"

export {
  en, zh, name
}