let en =  {
  title:"Create NFT",
  Image:"Image",
  Video:"Video",
  Audio:"Audio",
  ImageDec:"JPG, PNG, and GIF",
  VideoDec:"MP4, MPEG, and AVI",
  AudioDec:"MP3 and WAV",
  CollectionType:"Collection Type",
  ClickUpload:"Click to upload or drop the file here",
  Single:"Single",
  SingleDec:"Collection includes 1 NFT",
  Multiple:"Multiple",
  MultipleDec:"Collection includes multiple NFTs",
  Name:"Name",
  Description:"Description",
  Fees:"Fees",
  PayAndCreateNFT:"Pay and Create NFT",
  Count:"Count"
}

let zh = {
  title:"",
  Image:"",
  Video:"",
  Audio:"",
  ImageDec:"",
  VideoDec:"",
  AudioDec:"",
  CollectionType:"",
  ClickUpload:"",
  Single:"",
  SingleDec:"",
  Multiple:"",
  MultipleDec:"",
  Name:"",
  Description:"",
  Fees:"",
  PayAndCreateNFT:"",
  Count:""
}

let name = 'createNFT'

export {
  name, en, zh
}