let en =  {
  title:"History",
  Purchase:"Purchase",
  Sold:"Sold",
  Gift:"Gift",
  Name:"Name",
  Type:"Type",
  Time:"Time",
  Price:"Price",
  ContractAddress:"Contract Address",
  All:"All",
  OnSale:"On sale",
  SoldSucceed:"Sold Succeed",
  SoldFiled:"Sold Filed",
  Gifted:"Gifted",
  Received:"Received"
}

let zh = {
  title:"",
  Purchase:"",
  Sold:"",
  Gift:"",
  Name:"",
  Type:"",
  Time:"",
  Price:"",
  ContractAddress:""
}

let name = 'History'

export {
name, en, zh
}