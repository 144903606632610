import httpServer from '../httpServer'

// 个人中心 - 用户信息
function getUserInfo(address :any){
  return httpServer.doGet(`/user/${address}/user_info`)
}
//个人中心 - 修改用户信息
function setUserInfo(address :any,params:any){
  return httpServer.doPost(`/user/${address}/user_info/edit`,params)
}

//个人中心 - nft活动模块列表
function getNFTActivity(params:any){
  return httpServer.doGet(`/user/${params.address}/activity/user/a/${params.page}/${params.size}?t=0`)
}
//个人中心 - 新增nft活动
function addNFTActivity(params:any,address :any){
  return httpServer.doPost(`/user/${address}/activity/create/nft`,params)
}

//个人中心 - 修改nft活动
function editNFTActivity(params:any,address :any){
  return httpServer.doPost(`/user/${address}/activity/create/nft?edit=1`,params)
}

function NFTActivityData(address :any,id :any){
  return httpServer.doGet(`/user/${address}/activity/user/a_census/${id}`)
}

//个人中心 - 盲盒活动模块列表
function getBlindBoxActivity(params:any){
  return httpServer.doGet(`/user/${params.address}/activity/user/a/${params.page}/${params.size}?t=1`)
}

//个人中心 - 新增盲盒活动
function addBlindBoxActivity(params:any,address :any){
  return httpServer.doPost(`/user/${address}/activity/create/box`,params)
}

//个人中心 - 修改盲盒活动
function editBlindBoxActivity(params:any,address :any){
  return httpServer.doPost(`/user/${address}/activity/create/box?edit=1`,params)
}
//个人中心 - 盲盒活动数据
function boxActivityData(address :any,id :any){
  return httpServer.doGet(`/user/${address}/activity/user/a_census/${id}`)
}

//个人中心-history- 获取购买记录
function getPurchase(address :any,page :any,size :any){
  return httpServer.doGet(`/user/${address}/transaction/history/purchase/${page}/${size}`)
}

//个人中心- history- 获取转卖记录
function getSold(address :any,page :any,size :any){
  return httpServer.doGet(`/user/${address}/transaction/history/sold/${page}/${size}`)
}

//个人中心-history- 转赠礼物记录
function getGift(address :any,page :any,size :any,type :any){
  return httpServer.doGet(`/user/${address}/transaction/history/gift/${page}/${size}?t=${type}`) 
}

//个人中心 - 消息通知
function getNotification(address:any,page :any,size :any){
  return httpServer.doGet(`/user/${address}/message/${page}/${size}`)
}

//个人中心 - 消息已读
function readNotification(address:any,params :any){
  return httpServer.doPost(`/user/${address}/message/read`,params)
}

//个人中心 - nft藏品列表
function getUsernftList(params :any){
  return httpServer.doGet(`/user/${params.address}/user/bag/${params.page}/${params.size}?t=0&selling=${params.selling}&name=${params.name}&order=buy_price,${params.order}`)
}

//个人中心 - 盲盒藏品列表
function getUserBoxList(params :any){
  return httpServer.doGet(`/user/${params.address}/user/bag/${params.page}/${params.size}?t=1&selling=${params.selling}&name=${params.name}&order=buy_price,${params.order}`)
}

//个人中心 - 上架nft
function sellNFTToMarket(params:any,address :any){
  return httpServer.doPost(`/user/${address}/user_bag/sale/create`,params)
}
//个人中心 - 上架盲盒
function sellBlindBoxToMarket(params:any,address :any){
  return httpServer.doPost(`/user/${address}/user_bag/sale/create`,params)
}

//个人中心 - 转赠NFT
function sendNFTGift(address:any,params:any,count :any){
  return httpServer.doPost(`/user/${address}/send/box_or_nft?count=${count}`,params)
}
//个人中心 - 转赠盲盒
function sendBoxGift(address:any,params:any){
  return httpServer.doPost(`/user/${address}/send/box_or_nft`,params)
}
//个人中心 - 打开盲盒
function openBlindBoxSign(params :any,address :any){
  return httpServer.doPost(`/user/${address}/open/box`,params)
}

export{
  getUserInfo,
  setUserInfo,
  getNFTActivity,
  addNFTActivity,
  editNFTActivity,
  getBlindBoxActivity,
  addBlindBoxActivity,
  editBlindBoxActivity,
  getNotification,
  getUsernftList,
  getUserBoxList,
  sellNFTToMarket,
  sellBlindBoxToMarket,
  sendNFTGift,
  sendBoxGift,
  openBlindBoxSign,
  getPurchase,
  getSold,
  getGift,
  NFTActivityData,
  boxActivityData,
  readNotification
}