let en:any =  {
  Home:"Home",
  NFTS:"NFTS",
  BlindBoxes:"Mystery Boxes",
  Create:"Create",
  Faucet:"Faucet",
  ConnectWallet:"Connect Wallet"
}

let zh:any = {
  Home:"首页",
  NFTS:"",
  BlindBoxes:"",
  Create:"",
  Faucet:"",
  ConnectWallet:""
}

let name = 'nav'

export {
  name, en, zh
}