import { createStore } from 'vuex'
import wallet from './modules/wallet'
import popUps from './modules/popUps'
import setting from './modules/setting'
import menu from './modules/menu'

export default createStore({
  modules: {
    wallet,
    popUps,
    setting,
    menu
  },
})
