const LANG = "LANG"

function SetLang(lang) {
  window.localStorage.setItem(LANG, lang)
  return lang
}

function GetLang(defaultLang) {
  let localLang = window.localStorage.getItem(LANG)

  return localLang || defaultLang
}

export {
  SetLang,
  GetLang,
}