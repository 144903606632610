import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Validator from 'wallet-validator'
import utc from "dayjs/plugin/utc"
import Identicon from 'identicon.js'

let asSeconds = dayjs.extend(duration);
dayjs.extend(utc)

let FormatTime = function(time){
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
}
let utcForMatTime = function (time){
  return dayjs(time).utc().format("MMM,DD,YYYY");
}
let Timestamp = function(time){
  return dayjs(time).unix();
}
let DurationTime = function(time,unit){
  return dayjs.duration(time, unit).asSeconds();
}
//jschardet
let checkEncoding = function(base64Str) {
  let str = atob(base64Str.split(";base64,")[1]);
  var jschardet = require("jschardet");
  let encoding = jschardet.detect(str);
  encoding = encoding.encoding;
  if (encoding == "windows-1252") {
    encoding = "ANSI";
  }
  return encoding;
}

let group = function(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
}

let isValidAddress = function(address){
  if(address){
    var valid = Validator.validate(address,'ethereum');
    if(valid){
      return true;
    }else{
      return false;
    }
  }
  return false;
}

let getStringLength = (val)=> {
  let str = new String(val);
  let bytesCount = 0;
  for (let i = 0, n = str.length; i < n; i++) {
    let c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      bytesCount += 1;
    } else {
      bytesCount += 2;
    }
  }
  return bytesCount;
}

let formatterHash = (address)=>{
  if (address) {
    if (getStringLength(address) > 16) {
      return (
        address.substr(0, 8) + "..." + address.substr(address.length - 8)
      );
    } else {
      return address;
    }
  }
}

let formatterBalance = (balance)=>{
  if (balance) {
    if (getStringLength(balance) > 12) {
      return (
        balance.substr(0, 12) + "..."
      );
    } else {
      return balance;
    }
  }
}

function diffTime(startDate :any,endDate :any) { 
  startDate= new Date(startDate);
  endDate = new Date(endDate);
  var diff=endDate.getTime() - startDate.getTime();//时间差的毫秒数 
 
  //计算出相差天数 
  let days =Math.floor(diff/(24*3600*1000)); 
  let d = days < 10 ? '0' + days :days;
  //计算出小时数 
  var leave1=diff%(24*3600*1000);    //计算天数后剩余的毫秒数 
  var hours=Math.floor(leave1/(3600*1000)); 
  let h = hours < 10 ? '0' + hours : hours;
  //计算相差分钟数 
  var leave2=leave1%(3600*1000);        //计算小时数后剩余的毫秒数 
  var minutes=Math.floor(leave2/(60*1000)); 
  let m = minutes < 10 ? '0' + minutes : minutes;
  //计算相差秒数 
  var leave3=leave2%(60*1000);      //计算分钟数后剩余的毫秒数 
  var seconds=Math.round(leave3/1000); 
  let s = seconds < 10 ? '0' + seconds : seconds;
  return {
    days:d,
    hours:h,
    minutes:m,
    seconds:s
  }; 
} 

function getDefaultAvatar(address :any){
  if(getStringLength(address) > 16){
    let imgData = new Identicon(address, 420).toString()
    let defaultAvatar = 'data:image/png;base64,'+imgData;
    return defaultAvatar;
  }else{
    return '';
  }
}

export {
  FormatTime,
  Timestamp,
  DurationTime,
  checkEncoding,
  group,
  isValidAddress,
  formatterHash,
  formatterBalance,
  diffTime,
  utcForMatTime,
  getDefaultAvatar
}