let en =  {
  Pending:"Pending",
}

let zh = {
  Pending:"等待",
}

let name = 'faucetPending'

export {
  name, en, zh
}