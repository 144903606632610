let en =  {
  title:"Become a Publisher",
  Name:"Name",
  NameInput:"Name of Publisher",
  Telegram:"Telegram",
  TelegramInput:"Telegram Account",
  Email:"Email",
  EmailInput:"Email Address",
  PublicChain:"Public Chain",
  PublicChainInput:"Public Chain",
  Description:"Description",
  DescriptionInput:"Introduct your NFT",
  Submit:"Submit"
}

let zh = {
  title:"",
  Name:""
}

let name = 'BecomePublisher'

export {
name, en, zh
}