import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './js/i18n/index'
import VueClipboard from 'vue3-clipboard'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '@/styles/index.scss'

//font css
import '@/styles/fonts.scss'

const app = createApp(App)

app.use(router).use(store).use(i18n).use(ElementPlus).use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
}).mount('#app')
