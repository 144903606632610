export default {
  state: {
    popUpsResult: "",
    switchToChain: "",
    fromSwitch: {},
    executePop: false,
    connectWallet: false,
    switchChainPop: false,
    switchChainConfirm: false,
    connectWalletLoading: false,
    connectWalletErrLoading: false,
    blindBoxConfirm: false,
    blindBoxShow:false,
    airdropPop:false,
    airdropBoxList:[],
    airdropType:0,
    blindBoxInfo:{},
    faucetPopStatus:false,
    faucetStatus:0,
    faucetTime:""
  },
  getters: {
    fromSwitch: state => state.fromSwitch,
    executePop: state => state.executePop,
    popUpsResult: state => state.popUpsResult,
    connectWallet: state => state.connectWallet,
    switchToChain: state => state.switchToChain,
    switchChainPop: state => state.switchChainPop,
    switchChainConfirm: state => state.switchChainConfirm,
    connectWalletLoading: state => state.connectWalletLoading,
    connectWalletErrLoading: state => state.connectWalletErrLoading,
    blindBoxConfirm: state => state.blindBoxConfirm,
    blindBoxShow: state => state.blindBoxShow,
    airdropPop: state => state.airdropPop,
    airdropBoxList: state => state.airdropBoxList,
    airdropType: state => state.airdropType,
    blindBoxInfo: state => state.blindBoxInfo,
    faucetPopStatus: state=>state.faucetPopStatus,
    faucetStatus: state=>state.faucetStatus,
    faucetTime: state=>state.faucetTime
  },
  mutations: {
    updateFromSwitch(state, payload) {
      state.fromSwitch = payload
    },
    updateExecutePop(state, payload) {
      state.executePop = payload
    },
    updateSwitchChain(state, payload) {
      state.switchChainPop = payload
    },
    updatePopUpsResult(state, payload) {
      state.popUpsResult = payload
    },
    updateSwitchToChain(state, payload) {
      state.switchToChain = payload
    },
    updateConnectWallet(state, payload) {
      state.connectWallet = payload
    },
    updateSwitchChainConfirm(state, payload) {
      state.switchChainConfirm = payload
    },
    updateConnectWalletLoading(state, payload) {
      state.connectWalletLoading = payload
    },
    updateConnectWalletErrLoading(state, payload) {
      state.connectWalletErrLoading = payload
    },
    updateBlindBoxConfirm(state, payload) {
      state.blindBoxConfirm = payload
    },
    updateBlindBoxShow(state, payload) {
      state.blindBoxShow = payload
    },
    updateAirdropPop(state, payload) {
      state.airdropPop = payload
    },
    updateAirdropBoxList(state, payload) {
      state.airdropBoxList = payload
    },
    updateAirdropType(state, payload) {
      state.airdropType = payload
    },
    updateBlindBoxInfo(state, payload) {
      state.blindBoxInfo = payload
    },
    updateFaucetPopStatus(state, payload){
      state.faucetPopStatus = payload
    },
    updateFaucetStatus(state, payload){
      state.faucetStatus = payload
    },
    updateFaucetTime(state, payload){
      state.faucetTime = payload
    }
  },
  actions: {

  }
};
