import { web3Enable, web3Accounts, web3FromAddress } from '@polkadot/extension-dapp';
import { ApiPromise ,WsProvider} from '@polkadot/api';

let isPolkadot = function(){
    if(Object.keys((window as any).injectedWeb3).length===0){
        return true;
    }else{
        return false;
    }
}

let connentPolkadot = async function(){
    const allInjected = await web3Enable('my cool dapp');
    if (allInjected.length === 0) {
        return;
    }
    const allAccounts = await web3Accounts();
    return allAccounts[0].address;
}

let polkadotInjector = async function(SENDER){
    const injector = await web3FromAddress(SENDER);
    return injector
}

let initPolkadot = async function(){
    const wsProvider = new WsProvider('wss://rococo-canvas-rpc.polkadot.io');
    const api = await ApiPromise.create({provider:wsProvider});
    return api
}

export{
  isPolkadot,
  connentPolkadot,
  polkadotInjector,
  initPolkadot
}