import { createRouter, createWebHashHistory } from 'vue-router';

import wallet from "@/js/web3/web3";
import store from '../store';
import { initContracts, contractAddress } from "@/js/web3/contract";
import home from './modules/home';
import PersonalCenter from './modules/PersonalCenter';
import Markets from './modules/Market';
import CreateNFT from './modules/CreateNFT';
import Activity from './modules/Activity';
import Faucet from './modules/Faucet';
import Themes from './modules/Themes';
import BecomePublisher from './modules/BecomePublisher';
import { getUserInfo } from "@/js/network/serverApi/PersonalCenter";
import Identicon from 'identicon.js'

const routes =[{
  path: '/',
  name: 'mian',
  redirect: '/home',
  component: () => import('@/views/main/index.vue'),
  children:[
    home,
    Markets,
    Themes,
    CreateNFT,
    // Faucet,
    Activity,
    PersonalCenter,
    // BecomePublisher
  ]
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, _from, next:any) => {
  window.scroll(0,0)
  store.commit('updateRouterName',to.name)
  wallet.isMetaMask().then((res)=>{
    if(!res){
      next()
    }else{
      wallet.loadWallet().then(() => {
        wallet.getChainId().then( async(res) => {
          let account = await (window as any).ethereum.request({ method: 'eth_accounts' });
          if(account.length !== 0){
            store.commit('updateChainId', res)
            store.commit('updateChainName', res)
            store.commit('updateAccountsChanged',(window as any).ethereum.selectedAddress)
            store.commit('updateWalletType','MetaMask');
            (window as any).contract = await initContracts();
            (window as any).addressMap = await contractAddress();
            let userInfo = await getUserInfo((window as any).ethereum.selectedAddress);
            store.commit('updateUserInfo',userInfo["data"].data)

            let imgData = new Identicon((window as any).ethereum.selectedAddress, 420).toString()
            let defaultAvatar = 'data:image/png;base64,'+imgData;
            store.commit('updateDefaultAvatar',defaultAvatar)
          }
          next()
        })
      })
    }
  })
})

export default router
