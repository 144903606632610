let en =  {
  title:"World’s Most  Fun NFT Marketplace",
  content:"Mystery Box, collectables, and more",
  blindBoxesButton:"Mystery Boxes",
  NFTSButton:"NFTS",
  CreateButton:"Create Your Own"
}

let zh = {
  title:"",
  content:"",
  blindBoxesButton:"",
  NFTSButton:"",
  CreateButton:""
}

let name = 'NFTMarketplace'

export {
name, en, zh
}